// noinspection SpellCheckingInspection
// export type VendorName = 'upcontent' | 'dealervenom'
import hearsayConfig from '@/data/whitelabels/hearsay'
import everyoneSocialConfig from '@/data/whitelabels/everyone-social'

let upcontent = 'UpContent';
let dealerVenom = 'DealerVenom';
let socialAdvisors = 'SocialAdvisors'
let vendors = {
    upcontent,
    dealerVenom,
    socialAdvisors,
    hearsay: 'hearsay',
    hootsuite: 'hootsuite',
    everyoneSocial: 'everyone-social'
};

let customShareThroughFunction = [vendors.hootsuite];
let customShareThroughMessaging = [];

export function isHttps() {
    return window.location.protocol === 'https:'
}

export function topLevelDomain(hostname=window.location.hostname) {
    return hostname.split('').slice(-2).join('.');
}

export function domainVendor(hostname=window.location.hostname) {
    let topDomain = hostname.split('.').slice(-2)[0].replace('-', '');
    if (topDomain !== 'upcontent') {
        if (topDomain === 'hearsaysocial')
            return 'hearsay'
        return topDomain
    } else {
        let bottomDomain = hostname.split('.')[0].replace('-', '');
        if (bottomDomain === 'my')
            return 'upcontent';
        else if (bottomDomain === 'everyonesocial')
            return vendors.everyoneSocial;
        else
            return bottomDomain;
    }
}

export function isUpContent(vend=vendor()) {
    return vend === upcontent;
}

export function isLocal() {
    return window.location.hostname === 'localhost';
}

export function isQa() {
    return window.location.hostname.includes('qa.');
}

export function vendor() {
    try {
        if (isHootsuiteDomain()) return vendors.hootsuite;
        if (isLocal()) return upcontent;
        return formatVendor(domainVendor()) || upcontent;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return upcontent;
    }
}

export function appByUpcontent(vendor) {
    if (vendor === 'hootsuite') {
        return 'Curate'
    } else if (vendor === 'everyone-social') {
        return 'Ignite'
    } else return false
}

export function vendorEmbedShareProvider(vend=vendor()) {
    if (isInIFrame()) {
        if (vend === vendors.upcontent && domainVendor() === 'upcontent')
            vend = vendors.hootsuite;
        if (customShareThroughFunction.includes(vend)) {
            return {
                type: 'function',
                vendor: vend
            }
        } else if (customShareThroughMessaging.includes(vend)) {
            return {
                type: 'messaging',
                vendor: vend
            }
        }
    }
    return false;
}

export function bannedIntegrationsForVendor(vend=vendor()) {
    switch (vend) {
        case 'hootsuite':
            return ['buffer', 'funnelAmplified']
        case 'hearsay':
            return [
                'funnelAmplified',
                'hootsuite',
                'amplify',
                'industry weapon',
                'buffer',
                'proofpoint',
            ]
        case 'everyone-social':
            return [
                'funnelAmplified',
                'amplify',
                'industry weapon',
            ]
        default:
            return []
    }
}

export function subvendor() {
    return isAmplifyDomain() ? 'amplify' : ''
}

export function isAmplifyDomain() {
    return window.location.hostname === 'amplify.upcontent.com'
}

export function formatVendor(vendor) {
    return vendors[Object.keys(vendors).find(k => vendors[k].toLowerCase() === vendor)];
}

export function isInIFrame() {
    return window.parent !== window;
}

export function isHootsuite(vend=vendor()) {
    return vend.toLowerCase() === vendors.hootsuite.toLowerCase();
}

export function isHootsuiteDomain() {
    return window.location.hostname.includes('hootsuite') || isAmplifyDomain();
}

export function isDealerVenom(vend=vendor()) {
    // noinspection SpellCheckingInspection
    return vend.toLowerCase() === vendors.dealerVenom.toLowerCase()
}

export function isHearsay(vend=vendor()) {
    return vend.toLowerCase() === vendors.hearsay.toLowerCase()
}

export function isEveryoneSocial(vend=vendor()) {
    return vend.toLowerCase() === vendors.everyoneSocial.toLowerCase()
}

export function isSocialAdvisors(vend=vendor()) {
    // noinspection SpellCheckingInspection
    return false;//vend.toLowerCase() === vendors.socialAdvisors.toLowerCase()
}

export function isEmbed() {
    return window.isEmbed || false;
}

export function isChromeExtension() {
    try {
        return chrome && chrome.tabs && chrome.tabs.query;
    } catch {
        return false
    }
}

export function isBrowserExtension() {
    return isChromeExtension();
}

export const whitelabelConfigs = {
    hearsay: hearsayConfig,
    [vendors.everyoneSocial]: everyoneSocialConfig,
}

export function whitelabelConfig(vendor=vendor()) {
    return whitelabelConfigs[vendor]
}
